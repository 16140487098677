<template>
  <transition
    @enter="enter"
    @leave="leave"
    :css="false"
  >
    <div v-if="sharedState.showIntroVideo" class="fixed z-max bg-black absolute--fill">
      <button @click="close" class="bg-transparent pointer bw0 absolute top-2 right-2 outline-0 z-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
          <g fill="#FFFFFF" fill-rule="evenodd" transform="rotate(45 19.328 9.672)">
            <rect width="1" height="33" x="16"></rect>
            <rect width="1" height="33" x="16" transform="rotate(-90 16.5 16.5)"></rect>
          </g>
        </svg>
      </button>

      <div class="absolute absolute--fill flex justify-center items-center">
        <iframe class="w-100 vh-100" :src="`https://www.youtube.com/embed/${privateState.videoId}?autoplay=1&showinfo=0&modestbranding=1&rel=0`" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </transition>
</template>

<script>
import anime from "animejs"
import s from "../store"

export default {
  name: "IntroVideo",
  data () {
    return {
      privateState: {
        videoId: this.videoId,
        origin: this.origin
      },
      sharedState: s.state
    }
  },
  props: {
    videoId: { type: String },
    origin: { type: String }
  },
  mounted () {},
  methods: {
    close: () => {
      s.toggleIntroVideoAction()
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 400,
        easing: "easeOutCubic",
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 400,
        easing: "easeOutCubic",
        complete: done
      })
    }
  },
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
