<template>
  <ul class="ral-colors-palette--table js-ral-colors-palette--table">
    <li v-for="(color, key) in this.ralColors"
        :style="{ backgroundColor: color.HEX }"
        @click="selectCustomColor(color.RAL)"
    >
      <label :for="'ralColorCode-' + key"
          :class="{active: color.RAL === selectedColor}"
      >
        <span :class="isLight(color) ? 'dark' : 'light'">{{ color.RAL }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
import store from '../../store'
import axios from "axios";
import tinycolor from "tinycolor2";
import s from "../../store";

export default {
  name: 'RalColorPaletteSelector',
  components: {
  },
  mounted () {
    const locale = document.documentElement.getAttribute('lang')
    axios.get('/' + locale + '/ralcolors.json')
        .then((response) => this.ralColors = response.data)
  },
  data: () => ({
    ralColors: [],
    store: store,
    state: store.state,
    custom: !!store.state.additionInfo.v_color,
    product: store.state.selectedColorProduct,
  }),
  computed: {
    isMobile() {
      return window.innerWidth < 960;
    },
    selectedColor() {
      return store.state.additionInfo.v_color ? store.state.additionInfo.v_color : ''
    },
  },
  methods: {
    _translate (key) { return store._translates(key)},
    isLight(ralColor) {
      return tinycolor(ralColor.HEX).isLight();
    },
    selectCustomColor(color) {
      store.setAdditionInfo('v_color', color)
      if (this.isMobile) {
       // store.state.selectedColorProduct = this.product
      }
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  margin-top: 20px;
  position: relative;
  height: 12.5rem;
  //width: 200px;
  right: 0;
  z-index: 1;

  @media(min-width: 961px) {
    position: absolute;
  }

  li {
    float: left;
  }

  label {
    transition: all .2s;
  }
}
</style>
