<template>
  <div class="popup">
    <div class="popup__overlay" @click="$emit('close')"></div>
    <div class="popup__content">
      <button class="popup__close"
              type="button"
              title="Close popup"
              @click="$emit('close')">
        <svg class="v-mid" width="12" height="12" style="fill:currentColor">
          <path d="M1 2.5L2.5 1 6 4.5 9.5 1 11 2.5 7.5 6 11 9.5 9.5 11 6 7.5 2.5 11 1 9.5 4.5 6"/>
        </svg>
      </button>
      <div class="popup__title"
           v-if="title">
        {{ title }}
      </div>
      <div class="popup__body"
           v-html="html"></div>
    </div>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
let scrollBlock = document.body

export default {
  name: "PopupComponent",
  props: {
    html: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  mounted() {
    scrollBlock = document.querySelector('.popup__content')
    disablePageScroll(scrollBlock);
  },
  beforeDestroy() {
    enablePageScroll(scrollBlock);
  },
}
</script>

<style scoped lang="scss">
  .popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    &__title {
      font-family: Ladislav, sans-serif;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
      margin-bottom: 1rem;
    }

    &__close {
      display: flex;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      margin-left: 1rem;
      padding: 10px;
      background-color: #fff;
      border-radius: 50%;
      border: none;
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }

    &__overlay {
      cursor: pointer;
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-color: #181818;
    }

    &__content {
      margin: auto;
      width: 752px;
      color: #fff;
      z-index: 1;
      padding: 4rem;
      background-color: #333;
      position: relative;
      max-height: 100%;
      overflow: auto;
    }

    &__body {
      white-space: pre-line;
    }
  }
</style>
