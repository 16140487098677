<template>
  <div class="product__v_room_divider">
    <div v-for="roomDivider in items"
         :key="roomDivider.id"
         class="product__corner"
         @click="changeValue(roomDivider)"
         :class="{'product__corner_active': store.getSelectedOption('v_room_divider').id === roomDivider.id}">
      <div class="product__corner-image expand__option"
           :style="computedStyle(roomDivider)"></div>
      <div class="product__corner-title">{{ roomDivider.title }}</div>
      <div class="product__corner-cost">{{ roomDivider.vPriceModificator | toEur }}</div>
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: "ProductRoomDivider",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    store: store
  }),

  computed: {
  },
  methods: {
    changeValue(corner) {
      this.$emit('costChange','v_room_divider',corner)
    },
    image (corner) {
      if (corner.cover.length) return corner.cover[0]
      return ''
    },
    computedStyle (corner) {
      return {
        backgroundImage: `url('${this.image(corner)}')`,
        backgroundPosition: 'right bottom',
        backgroundSize: 'cover'
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .product {
    &__corner {
      flex-basis: 100%;
      cursor: pointer;
      text-align: center;

      &-image {
        border-radius: 2px;
        border: solid 2px #333333;
        height: 80px;
        margin-bottom: 1rem;
        transition: 0.3s;
      }

      &-title {
        line-height: 1.5;
        color: #fff;
        transition: 0.3s;
      }

      &-cost {
        font-size: 14px;
      }

      &:hover {
        .product {
          &__corner {
            &-image {
              border-color: #616161;
            }

            &-title {
              color: #fff;
            }
          }
        }
      }

      &_active {
        .product__corner {
          &-image {
            border-color: #ffd600;

            &:hover {
              border-color: #ffd600;
            }
          }

          &-title {
            color: #fff;
          }
        }
      }
    }

    &__v_room_divider {
      display: flex;
      gap: 1.5rem;
    }
  }
</style>
