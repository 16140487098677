<template>
  <div class="expand__option"
       :class="{'expand__option_left': left}"
       :style="computedStyle" >
    <div class="expand__option-title">{{ title }}</div>
    <div class="expand__option-cost" v-if="!short">{{ cost | toEur }}</div>
    <MoreInformation v-if="info"
                     :title="title"
                     :description="info">
      <div class="expand__option-info"></div>
    </MoreInformation>
  </div>
</template>

<script>
import MoreInformation from './MoreInformation.vue';
export default {
  name: 'ExpandHorizontalOption',
  components: {MoreInformation},
  props: {
    short: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      required: false,
    },
    imageSize: {
      type: String,
      default: '50% auto',
    },
    title: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      default: 0,
    },
    info: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundImage: '',
        backgroundPosition: 'left center',
        backgroundSize: this.imageSize
      }
    },
  },
  methods: {
    lazyLoad() {
      let backgroundImageUrl = this.image ? `url('${this.image}')` : '';
      let el = this.$el
      if ("IntersectionObserver" in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              entry.target.style.backgroundImage = backgroundImageUrl
              lazyBackgroundObserver.unobserve(entry.target);
            }
          });
        });
        lazyBackgroundObserver.observe(el)
      }
    }
  },
  mounted() {
    this.lazyLoad()
  }
}
</script>

<style scoped lang="scss">
.expand__option {
  width: 100%;
  height: auto;
  border-radius: 2px;
  border: solid 2px #333333;
  padding: 1.75rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  justify-content: flex-end;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  cursor: pointer;
  transition: 0.3s;
  background-size: 50% auto;
  background-position: center left;

  &.expand__lazy-load {
    visibility: hidden;
  }

  &-info {
    opacity: 0.5;
    background-image: url("../../assets/info.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &_left {
    justify-content: flex-start;
  }

  &-title {
    color: #fff;
    transition: 0.3s;
  }

  &:hover {
    border-color: #616161;

    .expand__option-title {
      color: #fff !important;
    }
  }

  &_active {
    border-color: #ffd600;

    &:hover {
      border-color: #ffd600;
    }

    .expand__option-title {
      color: #fff !important;
    }
  }

  &_disabled {
    opacity: 0.2;
  }
}
</style>
