<template>
  <div>
    <p>
      <span v-html="expanded ? fullText : shortText"></span>
      <button
        @click.prevent="toggle"
        :title="expanded ? btnLabelExpanded : btnLabelCollapsed"
        class="sans-serif f5 lh-copy bg-transparent bn outline-0 pointer product__show-more"
      >{{ expanded ? btnLabelExpanded : btnLabelCollapsed }}</button>
      <a
        v-if="writeReviewText && expanded"
        :href="contactUrl"
        class="write-more dim"
      >{{ writeReviewText }}</a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    btnLabelCollapsed: { type: String },
    btnLabelExpanded: { type: String },
    descriptionText: String,
    writeReviewText: String,
    contactUrl: String,
    isDescription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      decodedFullText: "",
      hideDots: false
    };
  },
  mounted() {
    if (!this.isDescription) {
      this.setDecodeFullText()
    }
  },
  methods: {
    setDecodeFullText() {
      const objectValue = JSON.parse(this.descriptionText);
      this.hideDots = false;
      if (typeof (objectValue.hideDots) != 'undefined') {
        this.hideDots = objectValue.hideDots
      }
      this.decodedFullText = objectValue;
    },
    toggle() {
      this.expanded = !this.expanded;
    }
  },
  computed: {
    shortText() {
      if (this.isDescription) {
        const shortDescriptionLength = 140
        if (this.descriptionText.length > shortDescriptionLength) {
          return `${this.descriptionText.slice(0, shortDescriptionLength)} ...`
        }

        return this.descriptionText
      }

      return this.decodedFullText.excerpt + (this.hideDots ? '' : '...')
    },
    fullText() {
      if (this.isDescription) {
        return this.descriptionText
      }

      return this.decodedFullText.fullText
    }
  }
};
</script>

<style lang="scss" scoped>
.nested-links button {
  color: #fff;
}
.nested-links button:focus,
.nested-links button:hover {
  color: gold;
  transition: color 0.15s ease-in;
}

.write-more {
  color: #ffd700 !important;
}
</style>
