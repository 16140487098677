<template></template>
<script>
export default {
  name: 'LangLinkModifier',
  props: {
    links: {
      type: Object,
      required: true
    }
  },
  mounted () {
    for (const lang in this.links) {
      const elems = document.querySelectorAll(`[hreflang="${lang}"]`);
      for (let i = 0; i < elems.length; i++) {
        const path = `${this.links[lang]}${location.search}`;
        elems[i].setAttribute('href', `${location.origin}/${lang}${path ? '/' + path : ''}`)
      }
    }
  }
}
</script>
