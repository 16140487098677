<template>
  <label class="color-samples-item"
         :class="{ 'variant-disabled': isDisabled }"
         @chage="carrySelectedSamples"
  >
    <div
        class="color-samples-item-color"
        :style="[this.isSemiGlassMaterial ? {'background-image': 'linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)', backgroundColor: this.bgColor} : {backgroundColor: this.bgColor}]"
    >
                <span
                    class="flex justify-center items-center color-samples-item-selected"
                    :class="{'variant-selected': this.isSelected, 'black': isLightBg}"
                >
                  {{ _translate("Selected") }}
                </span>
    </div>
    <div class="flex justify-between">
      <div class="flex flex-column">
        <div class="f6 color-samples-item-name">{{ variant.title }}</div>
        <div class="f6 grey" v-if="variant.finishType">{{ variant.finishType.title }}</div>
        <div class="f6 grey" v-else-if="variant.colorCode">{{ variant.colorCode }}</div>
      </div>
      <span class="color-samples-item-checkbox">{{ _translate("Add") }}
                        <input
                            type="checkbox"
                            name="selected_color_sample"
                            @change="carrySelectedSamples"
                            :checked="this.isSelected"
                            :disabled="this.isDisabled"
                        >
                        <span
                            class="checkmark"
                        ></span>
            </span>
    </div>
    <span class="f6 grey font-italic" v-if="variant.note">{{ variant.note }}</span>
  </label>
</template>

<script>
import store from "../../store";
import tinycolor from "tinycolor2";
import {translatesMixin} from "../../mixins/translatesMixin";
import {sampleLimiterMixin} from "../../mixins/sampleLimiterMixin";

export default {
  name: "ColorSamplesVariant",
  mixins: [translatesMixin, sampleLimiterMixin],
  components: {},
  data() {
    return {
      state: store.state,
      selected: false,
      isHoverState: false,
      bgColor: '',
    }
  },
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  methods: {
    carrySelectedSamples() {
      // Use date to further sort objects by recently selected
      const variant = {...this.variant, ...{timeSelected: Date.now()}}
      if (!this.isSelected) {
        store.setSelectedSample(variant.id, variant);
      } else {
        store.setSelectedSample(variant.id, null);
      }
    },
  },
  computed: {
    isDisabled() {
      return this.isSelectedSamplesLimitReached() && !store.getSelectedSample(this.variant.id);
    },
    isSelected() {
      return store.getSelectedSample(this.variant.id)
    },
    isSemiGlassMaterial() {
      return this.variant.finishType !== null && this.variant.finishType.id === 66314;
    },
    isLightBg() {
      const colorObject = this.variant.sampleColor;
      this.bgColor = colorObject.rgb;
      return tinycolor(this.bgColor).isLight()
    },
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
.variant-selected {
  opacity: 1;
}
.variant-disabled {
  user-select: none;
}
</style>