<template>
  <div class="color-samples-products" id="color-samples">
    <div class="color-samples-variants" v-for="(variants, colorName) in filteredProducts" v-if="variants.length > 0">
      <h3 class="color-samples-variants-title serif">{{ _translate(colorName) }}</h3>
      <div class="color-samples-variants-list">
        <color-samples-variant v-for="variant in variants" :variant="variant" />
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import ColorSamplesVariant from "./ColorSamplesVariant.vue";
import {translatesMixin} from "../../mixins/translatesMixin";

export default {
  name: "ColorSamplesProducts",
  mixins: [translatesMixin],
  components: {
    ColorSamplesVariant,
  },
  data() {
    return {
      state: store.state,
      isHovering: false,
    }
  },
  props: {
    products: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredProducts() {
      // Clone object to avoid parent property mutation
      let filteredCarry = {...this.products};

      if (this.state.selectedOptions.sampleColor !== undefined) {
        for (let [colorName, variants] of Object.entries(filteredCarry)) {
          filteredCarry[colorName] = variants.filter((item) => this.state.selectedOptions.sampleColor.name === colorName)
        }
      }

      if (this.state.selectedOptions.sampleMaterial !== undefined) {
        for (let [colorName, variants] of Object.entries(filteredCarry)) {
          filteredCarry[colorName] = variants.filter((variant) => variant.finishType !== null && this.state.selectedOptions.sampleMaterial.handle === variant.finishType.slug);
        }
      }

      if (this.state.selectedOptions.searchValue !== undefined) {
        for (let [colorName, variants] of Object.entries(filteredCarry)) {
          const searchValue = this.state.selectedOptions.searchValue.toLowerCase();
          filteredCarry[colorName] = variants.filter((variant) =>
              colorName.toLowerCase().includes(searchValue)
              ||
              variant.title.toLowerCase().includes(searchValue)
              ||
              variant.title.toLowerCase().replace(/ /g,'').includes(searchValue)
              ||
              variant.finishType && variant.finishType.title.toLowerCase().includes(searchValue)
              ||
              variant.colorCode && variant.colorCode.toLowerCase().includes(searchValue)
          )
        }
      }

      return filteredCarry;
    },
  },
}
</script>

<style scoped lang="scss">

</style>