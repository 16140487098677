<template>
  <div class="product__description">
    <template v-if="shortDescription">
      <div v-html="computedHtml" class="product__description--list"></div>
      <button
          @click.prevent="toggle"
          :title="_translate(buttonLabel)"
          class="sans-serif f5 lh-copy bg-transparent bn outline-0 pointer product__show-more"
      >{{ _translate(buttonLabel) }}</button>
    </template>
    <template v-else>
      <expandable-text
          :is-description="true"
          :description-text="descriptionText.fullText"
          :btn-label-collapsed="_translate('Read more')"
          :btn-label-expanded="_translate('Read less')"
      />
    </template>
  </div>
</template>

<script>
import ExpandableText from './ExpandableText.vue'
import store from '../store.js'
import {translatesMixin} from "../mixins/translatesMixin";

export default {
  name: 'ExpandableDescription',
  mixins: [translatesMixin],
  components: {
    ExpandableText,
  },
  data() {
    return {
      expanded: false,
    }
  },
  props: {
    btnLabelCollapsed: {
      type: String,
      required: true,
    },
    btnLabelExpanded: {
      type: String,
      required: true,
    },
    descriptionText: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shortDescription() {
      const matchPattern = /(<p>.*<\/p>|<[o|u]l>.*<\/[o|u]l>)/gmiu
      const matches = this.descriptionText.fullText.match(matchPattern)

      return matches ? matches[0] : null
    },
    computedHtml() {
      return this.expanded ? this.descriptionText.fullText : this.shortDescription
    },
    buttonLabel: function() {
      return this.expanded ? this.btnLabelExpanded : this.btnLabelCollapsed;
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<style scoped lang="scss">
  .product__description--list {
    ::v-deep {
      ul {
        list-style: none;
      }

      ul li::before {
        @media screen and (max-width: 60em) {
          margin-left: 0;
          transform: translateX(-0.5rem);
        }

        content: "\2022";
        color: #ffd600;
        font-weight: bold;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
        transform: translateX(-0.5rem);
      }
    }
  }
</style>
