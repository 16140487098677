<template>
  <div class="color-samples-pack mb5">
    <side-cart-item-sample
        v-for="item in samples"
        :id="item.id"
        :purchasable-id="item.purchasableId"
        :color="item.color"
        :options="item.options"
        :optionsSignature="item.optionsSignature"
        :qty="item.qty"
        :price="item.price"
        :salePrice="item.salePrice"
        :key="item.id"
        :variant="item.variant"
    />
    <div class="flex justify-end custom-color-samples-buttons">
<!--      <button type="button">Edit</button>-->
      <form
          method="post"
          action
          accept-charset="UTF-8"
          @submit.prevent="updateQty"
          ref="form"
      >
        <input type="hidden" name="action" value="commerce/cart/update-cart">
        <input v-for="item in samples" type="hidden" :name="'lineItems['+item.id+'][id]'" :value="item.id">
        <input v-for="item in samples" type="hidden" :name="'lineItems['+item.id+'][qty]'" :value="0">

        <button>{{ _translate('Remove') }}</button>
      </form>
      <span>€{{ totalCustomSamplesPrice }}</span>
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
import axios from 'axios'
import { throttle, debounce } from 'lodash';
import h from '../../helpers'
import s from '../../store'
import store from '../../store';
import {sampleLimiterMixin} from "../../mixins/sampleLimiterMixin";
import SideCartItemSample from "./SideCartSampleItem.vue";
import {translatesMixin} from "../../mixins/translatesMixin";
export default {
  name: 'SideCartCustomSamplesPack',
  components: {SideCartItemSample},
  mixins: [sampleLimiterMixin, translatesMixin],
  data () {
    return {
      privateState: {
        // qty: this.qty,
        // purchasable: null,
      },
      sharedState: s.state,
    }
  },
  props: {
    samples: { type: Array },
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    _translate(key) {
      return store._translates(key)
    },
    translateSentence (text) {
      return text.split(' ').map(word => this._translate(word)).join(' ');
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    updateQty: debounce(function() {
      this.$nextTick(() => {
        this.sharedState.cartUpdated = true;
        const form = this.$refs.form

        // Post request
        const settings = {
          url: '/',
          data: h.getFormData(form, s.state.csrf),
          method: 'POST',
          headers: {
            'Content-type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }

        const locale = document.documentElement.getAttribute('lang')

        axios(settings)
            .then(response => {
              axios
                  .get('/' + locale + '/api/v1/cart.json')
                  .then(response => {
                    const data = response.data.data[0]
                    // Put the whole thing into the state
                    s.setCartAction(data);
                    s.setSamplesInCart();
                  }).finally(() => {
                    this.sharedState.cartUpdated = false;
                  })
            })
            .catch(function (error) {
              if (s.debug) console.log(error)
            })
      })
    }, 600)
  },
  computed: {
    totalCustomSamplesPrice() {
      const customSamplesItems = Object.values(this.sharedState.cart.lineItems).filter((item) => item.productType === 'colorSamples');
      let totalPrice = 0;
      if (!customSamplesItems) {
        return totalPrice;
      }
      customSamplesItems.forEach(item => {
        totalPrice += item.hasOwnProperty('salePrice') ? item.salePrice : 0
      });

      return totalPrice.toFixed(2);
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-color-samples-buttons {
  gap: 48px;

  button {
    background: none;
    border: none;
    color: #ffffff;
    text-decoration: underline;
  }
  button:hover, button:focus {
    color: #ffd600;
    cursor: pointer;
    transition: all .3s;
  }
}

.color-samples-pack {
  margin-top: 24px;
}

.side-item {
  &__title {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 1rem;

    @media screen and (max-width: 60em) {
      font-size: 2.25rem;
    }
  }

  &__subtitle {
    line-height: 1;
    margin-bottom: 1rem;
    color: #fff;
  }

  @media screen and (max-width: 60em) {
    margin-bottom: 2.5rem;
  }
}

.color-square {
  margin-left: auto;
  width: 48px;
  height: 48px;
}

.faux-text {
  float: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faux-text:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #222;
}
</style>
