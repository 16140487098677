<template>
  <form @submit.prevent="onSubmit" method="post" :data-vv-scope="scope" accept-charset="UTF-8" class="">
    <slot :accountType="accountType" :isPartner="isPartner" :setAccountType="setAccountType" :componentKey="componentKey"></slot>
  </form>
</template>

<script>
export default {
  name: "RegistrationForm",
  emits: ['submit'],
  props: {
    scope: {
      type: String,
      required: true,
    },
    initialAccountType: {
      type: String,
      default: ''
    }
  },
  computed: {
    isPartner () {
      return this.accountType === 'partner'
    }
  },
  methods: {
    onSubmit ($event) {
      this.$emit('submit', {
        data: $event,
        isPartner: this.isPartner
      })
    },
    setAccountType (type) {
      this.componentKey += 1
      this.accountType = type
    }
  },
  data () {
    return {
      accountType: this.initialAccountType,
      componentKey: 0
    }
  }
}
</script>
