export const ScrollLink = (els) => {
  const links = Array.from(document.querySelectorAll(els))
  links.map(link => {
    link.addEventListener('click', function (event) {
      event.preventDefault()
      const targetSelector = this.getAttribute('data-scrollto')
      const target = document.querySelector(targetSelector)
      target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  })
}
