<template>
  <div class="input-component">
    <div class="input-component__label"
         :class="{'input-component__label_shown': value}">{{ computedPlaceholder }}</div>
    <input class="input-component__input"
           :required="required"
           :class="{invalid,success}"
           v-bind="{value,type,name,placeholder: computedPlaceholder}"
           @input="$emit('input', $event.target.value)"/>
    <div v-if="$slots"
         class="input-component__error-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  computed: {
    computedPlaceholder() {
      return (this.required) ? `${this.placeholder} *` : this.placeholder;
    }
  }
}
</script>

<style scoped lang="scss">
.input-component {
  &__label {
    position: absolute;
    opacity: 0;
    transition: 0.3s;
    z-index: 1;

    &_shown {
      transform: translateY(-22px);
      opacity: 0.9;
    }
  }

  &__input {
    z-index: 2;
    position: relative;
    height: 1.5rem;
    line-height: 1.5rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ffffff;
    outline: none;
    font-family: FuturaPT, serif;
    color: #ffffff;
    transition: 0.3s;

    &.invalid {
      border-color: #ff5858;
    }

    &.success {
      border-color: #58ff60;
    }
  }

  &__error-text {
    right: 0;
    font-family: FuturaPT,serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ff5858;
    position: absolute;
    margin-top: 0.5rem;
  }
}
</style>
