<template>
    <transition @enter="enter" :css="false">
      <div v-if="sharedState.faq" class="grid cf">
        <div class="mb5 mb6-l col col-12 col-2-l">
          <ul class="list">
            <li class="mb2 mb3-l" v-for="(category, i) in sharedState.faq" :key="i">
              <button
                  @click="setCategory(i)"
                  class="f5 f4-l serif white lh-title glow bg-transparent tl bn pointer outline-0"
                  :class="[i === privateState.activeCategory ? 'o-100' : 'o-50']"
              >{{ category.title }}
              </button>
            </li>
          </ul>
        </div>

        <div class="mb5 mb6-l col col-12 col-4-l push-1-l">
          <ul class="list">
            <li
                class="mb2 mb3-l"
                v-for="(question, i) in sharedState.faq[privateState.activeCategory].questions"
                :key="i"
            >
              <button
                  @click="setQuestion(i)"
                  class="f5 f4-l serif white lh-title glow bg-transparent tl bn pointer outline-0"
                  :class="[i === privateState.activeQuestion ? 'o-100' : 'o-50']"
              >{{ question.title }}
              </button>
            </li>
          </ul>
        </div>

        <div class="mb5 mb6-l col col-12 col-4-l push-1-l last nt1">
          <p
              class="mb4 sans-serif f4"
              v-html="sharedState.faq[privateState.activeCategory].questions[privateState.activeQuestion].text"
          ></p>
          <a
              v-if="sharedState.faq[privateState.activeCategory].questions[privateState.activeQuestion].button"
              :href="sharedState.faq[privateState.activeCategory].questions[privateState.activeQuestion].button.url"
              :title="sharedState.faq[privateState.activeCategory].questions[privateState.activeQuestion].button.title"
              :target="sharedState.faq[privateState.activeCategory].questions[privateState.activeQuestion].button.target"
              class="f5 ls-05 bw0 bg-yellow black-link-btn sans-serif black fw7 pv2 ph3 dib outline-0 mb2 br1 dim"
          >{{ sharedState.faq[privateState.activeCategory].questions[privateState.activeQuestion].button.title }}</a>
        </div>
      </div>
    </transition>
</template>

<script>
import axios from "axios"
import anime from "animejs"
import s from "../store"

export default {
  name: "Faq",
  data() {
    return {
      privateState: {
        activeCategory: 0,
        activeQuestion: 0
      },
      sharedState: s.state
    }
  },
  props: {
    heading: {
      type: String,
      default: 'FAQ'
    }
  },
  mounted() {
    const locale = document.documentElement.getAttribute("lang")
    axios
        .get("/" + locale + "/api/v1/faq.json")
        .then(response => {
          const data = response.data.data
          // Put the whole thang into the state
          s.setFaqAction(data)
        })
        .catch(function(error) {
          if (s.debug) {
            console.log(error)
          }
        })
  },
  methods: {
    setCategory(index) {
      if (s.debug) {
        console.log("setCategory triggered with", index)
      }
      this.privateState.activeCategory = index
      this.privateState.activeQuestion = 0
    },
    setQuestion(index) {
      if (s.debug) {
        console.log("setQuestion triggered with", index)
      }
      this.privateState.activeQuestion = index
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 400,
        easing: "easeOutCubic",
        complete: done
      })
    }
  },
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
a.black-link-btn {
  color: black;
  text-decoration: none;
}
a.black-link-btn:hover, a.black-link-btn:focus {
  color: black;
  text-decoration: none;
}
</style>
