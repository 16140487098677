import './style.scss'
const HeaderBackground = () => {
  const element = document.querySelector('.js-header-background')
  if (!element) return false

  const toggleActiveBackground = () => {
    let active = false
    if (window.scrollY !== 0 && !active) {
      element.classList.add('active')
      active = true
    }
    if (window.scrollY < 10 && active) {
      element.classList.remove('active')
      active = false
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
    toggleActiveBackground()
  })

  window.onscroll = function (e) {
    toggleActiveBackground()
  }
}
export default HeaderBackground
