<template class="color-samples-pack">
  <div>

    <section v-if="isMobile && (catalogButton !== undefined || samplesButton !== undefined)" class="color-samples-navigation">
      <a v-if="catalogButton !== undefined" href="#samples-catalog" class="color-samples-navigation-btn">
        <div>
          <p v-if="catalogButton.title" class="serif color-samples-navigation-btn-title">{{ catalogButton.title }}</p>
          <p v-if="catalogButton.description">{{ catalogButton.description }}</p>
        </div>
        <p v-if="catalogButton.price" class="price yellow">€{{ catalogButton.price }},-</p>
      </a>
      <a v-if="samplesButton !== undefined" href="#samples-variants" class="color-samples-navigation-btn">
        <div>
          <p v-if="samplesButton.title" class="serif color-samples-navigation-btn-title">{{ samplesButton.title }}</p>
          <p v-if="samplesButton.description">{{ samplesButton.description }}</p>
        </div>
        <p v-if="samplesButton.price" class="price yellow">€{{ samplesButton.price }},-</p>
      </a>
    </section>
    <!-- Top section -->
    <section class="color-samples-description" id="samples-catalog">
      <div class="flex justify-center items-center items-start-l flex-column flex-row-l">
        <h2 v-if="isMobile" class="serif color-samples__description-title mb3">
          {{ packProduct.title }}
        </h2>
        <template v-if="mediaPreview !== undefined">
          <img v-if="mediaPreview.fileKind === 'image'" class="color-samples-description-media" :src="mediaPreview.url">
          <video v-if="mediaPreview.fileKind === 'video'" class="color-samples-description-media" muted autoplay loop playsinline>
            <source :type="mediaPreview.mimeType" :src="mediaPreview.url">
          </video>
        </template>
        <div class="color-samples-description-block flex flex-column">
          <h2 v-if="!isMobile" class="serif color-samples__description-title">
            {{ packProduct.title }}
          </h2>
          <p v-if="!isMobile" class="price yellow mt2">€{{ packProduct.defaultPrice }},-</p>
          <expandable-description
              :is-description="true"
              :description-text="description"
              btn-label-collapsed="Read more"
              btn-label-expanded="Read less"
          />
          <p v-if="otherProductTypesInCart" class="yellow mb3">
            <strong>{{ _translate("Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order.") }}</strong>
          </p>
          <div class="flex justify-between" :class="{'order-first': isMobile}">
            <form method="POST"
                  @submit.prevent="updateCart"
                  ref="form"
            >
              <input type="hidden" name="action" value="commerce/cart/update-cart">
              <input type="hidden" :name="'purchasables['+packProduct.id+'][id]'" :value="packProduct.defaultVariantId"
                     id="inputPurchasableId">
              <input type="hidden" :name="'purchasables['+packProduct.id+'][qty]'" value="1">
              <button
                  :disabled="otherProductTypesInCart"
                  :class="{'disabled': otherProductTypesInCart}"
                  class="color-samples-pack__buy-btn pointer dim"
              >{{ _translate('Buy') }}</button>
            </form>
            <p v-if="isMobile" class="price yellow mt2">€{{ packProduct.defaultPrice }},-</p>
          </div>
        </div>
      </div>
    </section>

    <a v-if="!isMobile && packBanner !== undefined"
              href="#samples-variants"
             :style="{ backgroundImage: 'url(' + packBanner.image + ')' }"
             class="color-samples-banner">
      <p v-if="packBanner.title" class="color-samples-banner-title serif">{{ packBanner.title }}</p>
      <p v-if="packBanner.description" class="color-samples-banner-description">{{ packBanner.description }}</p>
      <p v-if="packBanner.price" class="price yellow">€{{ packBanner.price }},-</p>
    </a>

    <!-- Select and order samples -->
    <section v-if="!isMobile" class="color-samples-color-variants">
      <h2 class="color-samples__list-title serif">{{ _translate('Select sample per piece') }}</h2>
      <ul class="color-samples__list">
        <li v-for="(color, handle) in this.colors">
          <input type="radio"
                 name="colors"
                 :id="_translate(color.name)"
                 @change="changeColor(color.name, handle); scrollElementInView('color-samples', 300, 'smooth');"
                 :checked="selectedColor !== undefined && selectedColor.handle === handle"
                 :value="handle">
          <label :for="_translate(color.name)">
            <div class="color-outer-circle">
              <div class="color-inner-circle" :style="{backgroundColor: color.hex}"></div>
            </div>
            <span>{{ _translate(color.name) }}</span>
          </label>
        </li>
      </ul>
    </section>

    <!-- Products & sidebar -->
    <section class="color-samples-main-content" id="samples-variants">
      <div class="flex">
        <div v-if="!isMobile" class="color-samples-sidebar">
          <div class="color-samples-sidebar-section">
            <p class="color-samples-sidebar-title serif">{{ _translate('Finish') }}</p>
            <ul class="color-samples-filters-materials">
              <li class="color-samples-filters-color-item" v-for="(name, handle) in this.materials">
                <input type="radio"
                       name="sidebar-materials"
                       :id="'sidebar-' + handle"
                       @change="changeMaterial(name, handle)"
                       :value="handle">
                <label :for="'sidebar-' + handle">
                  <span class="radio-circle"></span>
                  {{ _translate(name) }}
                </label>
              </li>
            </ul>
          </div>
          <div class="color-samples-sidebar-section">
            <p class="color-samples-sidebar-title serif">{{ _translate('Colors') }}</p>
            <ul class="color-samples-filters-colors">
              <li class="color-samples-filters-colors-item" v-for="(color, handle) in this.colors">
                <input type="radio"
                       name="sidebar-colors"
                       :id="'sidebar-' + color.name"
                       @change="changeColor(color.name, handle)"
                       :checked="selectedColor !== undefined && selectedColor.handle === handle"
                       :value="handle">
                <label :for="'sidebar-' + color.name">
                  <span class="color-circle" :style="{backgroundColor: color.hex}"></span>
                  <span>{{ _translate(color.name) }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="color-samples-catalog">
          <!-- Search & Filters -->
          <color-samples-filters @resetMaterialFilter="uncheckMaterialInputs" @resetColorFilter="uncheckColorInputs"/>
          <!-- Products  -->
          <color-samples-products :products="packColors"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ColorSamplesFilters from "./ColorSamplesFilters.vue";
import ColorSamplesProducts from "./ColorSamplesProducts.vue";
import ColorSamplesSelector from "./ColorSamplesSelector.vue";
import ExpandableText from '../ExpandableText.vue'
import store from "../../store";
import h from "../../helpers";
import axios from "axios";
import {translatesMixin} from "../../mixins/translatesMixin";
import {commonMixin} from "../../mixins/commonMixin";
import {sampleLimiterMixin} from "../../mixins/sampleLimiterMixin";
export default {
  name: "ColorSamples",
  mixins: [translatesMixin, commonMixin, sampleLimiterMixin],
  components: {
    ColorSamplesFilters,
    ExpandableText,
    ColorSamplesProducts,
    ColorSamplesSelector,
  },
  data() {
    return {
      state: store.state,
    }
  },
  props: {
    packColors: {
      type: Object,
      required: true,
    },
    packProduct: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    materials: {
      type: Object,
      required: true,
    },
    mediaPreview: {
      type: Object,
      required: false,
    },
    description: {
      type: Object,
      required: false,
    },
    packBanner: {
      type: Object,
      required: false,
    },
    catalogButton: {
      type: Object,
      required: false,
    },
    samplesButton: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 960;
    },
    selectedColor() {
      return store.state.selectedOptions.sampleColor
    },
  },
  methods: {
    changeMaterial(materialName, materialHandle) {
      store.setSelectedOption('sampleMaterial', {
        'name': materialName,
        'handle': materialHandle,
      })
    },
    changeColor(colorName, colorHandle) {
      store.setSelectedOption('sampleColor', {
        'name': colorName,
        'handle': colorHandle,
      })
    },
    uncheckMaterialInputs() {
      const materialInputs = document.querySelectorAll('input[name="materials"]')
      const sidebarMaterialInputs = document.querySelectorAll('input[name="sidebar-materials"]')
      materialInputs.forEach(input => input.checked = false)
      sidebarMaterialInputs.forEach(input => input.checked = false)
    },
    uncheckColorInputs() {
      const colorInputs = document.querySelectorAll('input[name="colors"]')
      const sidebarColorInputs = document.querySelectorAll('input[name="sidebar-colors"]')
      colorInputs.forEach(input => input.checked = false)
      sidebarColorInputs.forEach(input => input.checked = false)
    },
    updateCart() {
      const form = this.$refs.form
      let data = h.getFormData(form, store.state.csrf)

      // Post request
      const settings = {
        url: '/',
        data,
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }

      const locale = document.documentElement.getAttribute('lang')
      axios(settings)
          .then(() => {
            axios
                .get('/' + locale + '/api/v1/cart.json')
                .then(response => {
                  const data = response.data.data[0]
                  // Put the whole thing into the state
                  store.setCartAction(data)
                  store.toggleSideCartAction()
                  store.setCsrf();
                })
          })
          .catch(function (error) {
            console.log(error)
          })
    },
  },
}
</script>

<style scoped lang="scss">
.product__description {
  margin: 16px 0;
}

.order-first {
  order: -1;
}
</style>