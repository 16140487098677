<template>
  <div class="select__wrapper" v-click-outside="outsideToggle">
    <div class="select__handler" @click="selectToggle">
      <div class="select__title">{{ computedTitle }}</div>
    </div>
    <div v-if="isShown" class="select__select">
      <div v-for="color in computedColors"
           :key="color.vSkuModificator"
           class="select__color"
           :class="`select__color_${color.slug}`"
           :style="{background: color.color}"
           @click="changeColor(color)">
        <span>{{ color.colorCode }}</span>
        <span v-if="color.isSelected"
              class="select__color-info">{{ _translate('Same color as shelf') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import store from '../store.js';

export default {
  name: 'ColorSelectComponent',
  data() {
    return {
      isShown: false,
    };
  },
  props: {
    value: {
      type: Object | null,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select color',
    },
    extendedColor: {
      type: Object,
      required: true,
    },
    availableColors: {
      required: true,
    },
  },
  computed: {
    computedTitle() {
      if (this.value) {
        const selectedColor = this.computedColors.find((item) => item.vSkuModificator === this.value.vSkuModificator);

        return typeof selectedColor === 'undefined' ? this.placeholder : selectedColor.colorCode;
      }

      return this.placeholder;
    },
    computedColors() {
      const availableColors = [...[...this.availableColors]]
      return availableColors.reduce((acc, item) => {
        item = {...item, isSelected: false}
        // 'Other color' category
        if (item.id === 58449) {
          item = {...item, vSkuModificator: 'CC'}
        }
        if (this.extendedColor.vSkuModificator === item.vSkuModificator) {
          item.isSelected = true
        }

        if (item.vSkuModificator === 'CC') {
          if (item.isSelected) {
            item = {
              ...item,
              vSkuModificator: 'CC',
              color: this.extendedColor.color,
              colorCode: this.extendedColor.colorCode
            }
            acc.push(item)
          }
          return acc;
        }
        acc.push(item)

        return acc
      }, [])
    },
  },
  methods: {
    _translate(key) {
      return store._translates(key)
    },
    selectToggle() {
      this.isShown = !this.isShown;
    },
    outsideToggle() {
      if (this.isShown) this.selectToggle();
    },
    changeColor(color) {
      this.selectToggle();
      this.$emit('input', color);
    }
  },
  directives: {
    ClickOutside
  },
}
</script>

<style scoped lang="scss">
  .select {
    &__handler {
      padding: 12px 48px 12px 16px;
      border-radius: 2px;
      border: solid 2px #333;
      cursor: pointer;
      background-image: url("/build/img/select-arrow.svg");
      background-position: center right 16px;
      background-repeat: no-repeat;
    }

    &__color {
      width: 100%;
      height: 40px;
      padding: 8px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #0d0d0d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &_black {
        color: #fff;
      }

      &-info {
        color: #fff;
        font-size: 14px;
      }
    }

    &__title {
      color: #fff;
    }

    &__select {
      padding: 8px;
      border-radius: 2px;
      box-shadow: 0 0 24px 0 #000;
      background-color: #333;
      position: absolute;
      left: 0;
      width: 100%;
      margin-top: 8px;
      z-index: 9;
    }
  }
</style>
