<template>
  <label class="radio__button"
         :class="{short}">
    <input class="radio__input"
           type="radio"
           :checked="isChecked"
           :value="value"
           @change="$emit('change', value);"/>
    <span class="radio__helper">
      {{ title }}
      <MoreInformation v-if="info"
                       class="radio__more-info"
                       :title="this.descriptionTitle"
                       :description="info">
        <div class="radio__info"></div>
      </MoreInformation>
      <span v-if="isChecked && isClearable"
            @click.prevent="$emit('clear')"
            class="radio__clear">x</span>
      <span v-if="isNew"
            class="radio__indicator_new">new</span>
    </span>
  </label>
</template>

<script>
import MoreInformation from './MoreInformation.vue';

export default {
  name: 'RadioButtonExtended',
  components: {
    MoreInformation,
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    isClearable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    modelValue: {
      required: true,
    },
    short: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
    infoTitle: {
      type: String,
      default: '',
    },
  },
  methods: {},
  computed: {
    isChecked() {
      return this.modelValue === this.value
    },
    descriptionTitle() {
      // In case for the extended info title
      if (this.infoTitle) {
        return this.infoTitle
      }

      return this.title
    },
  },
}
</script>

<style scoped lang="scss">
.radio {
  &__indicator_new,
  &__clear {
    margin-left: 0.5rem;
    color: #ffd600;
  }

  &__clear {
    margin-left: 1rem;
  }

  &__button {
    cursor: pointer;
    margin-bottom: 0.5rem;
    padding-right: 20px;
    width: 100%;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover .radio__helper {
      color: #ffffff;

      &::before {
        border-color: transparent;
        background: #ffd700;
      }
    }

    &.short {
      width: 40%;

      @media screen and (max-width: 60em) {
        width: 100%;
      }
    }

    @media screen and (max-width: 60em) {
      width: 100%;
      margin-bottom: 0;
      padding: 12px 0;
      text-align: center;
    }
  }

  &__helper {
    height: 1.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    transition: 0.3s;

    &::before {
      content: '';
      margin-right: 1rem;
      flex-shrink: 0;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: transparent;
      border: 0.125rem solid #b2b2b2;
      transition: 0.3s;
    }

    @media screen and (max-width: 60em) {
      justify-content: center;

      &::before {
        content: '';
      }
    }
  }

  &__input {
    display: none;

    &:checked + .radio__helper {
      color: #ffffff;

      &::before {
        border-color: transparent;
        background: #ffd700;
      }
    }
  }

  &__info {
    margin-left: 10px;
    opacity: 0.5;
    background-image: url("../../assets/info.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
