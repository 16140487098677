<template>
  <a :href="href" class="product__back">{{ text }}</a>

</template>

<script>
export default {
  name: 'LinkUpper',
  props:{
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    href() {
      return location.origin + location.pathname.split('/').slice(0,-1).join('/')
    }
  }
}
</script>

<style scoped>

</style>