<template>
  <div class="mb3 side-item">
    <transition @enter="enter" @leave="leave" :css="false">
    <div class="flex mb3">
      <div class="w-80">
          <p>{{ variant.title }}</p>
          <p class="grey">{{ variant.finishType ? variant.finishType.title : variant.colorCode }}</p>
      </div>
      <div class="w-20">
          <div class="color-square" :style="[variant.finishType !== null && variant.finishType.id === 66314
          ?
          {'background-image': 'linear-gradient(27deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%)', backgroundColor: variant.sampleColor.rgb}
          :
          {background: variant.sampleColor.rgb}]"></div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import anime from 'animejs'
import axios from 'axios'
import { throttle, debounce } from 'lodash';
import h from '../../helpers'
import s from '../../store'
import store from '../../store';
import {sampleLimiterMixin} from "../../mixins/sampleLimiterMixin";
export default {
  name: 'SideCartItemSample',
  mixins: [sampleLimiterMixin],
  data () {
    return {
    }
  },
  props: {
    id: { type: Number },
    purchasableId: { type: [ String, Number ] },
    color: { type: Object },
    size: { type: String },
    qty: { type: Number },
    price: { type: Number },
    salePrice: { type: Number },
    variant: { type: Object, default: () => null }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    _translate(key) {
      return store._translates(key)
    },
    translateSentence (text) {
      return text.split(' ').map(word => this._translate(word)).join(' ');
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: 'easeOutCubic',
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 300,
        easing: 'easeOutCubic',
        complete: done
      })
    },
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.side-item {
  &__title {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 1rem;

    @media screen and (max-width: 60em) {
      font-size: 2.25rem;
    }
  }

  &__subtitle {
    line-height: 1;
    margin-bottom: 1rem;
    color: #fff;
  }

  @media screen and (max-width: 60em) {
    margin-bottom: 2.5rem;
  }
}

.color-square {
  margin-left: auto;
  width: 48px;
  height: 48px;
}

.faux-text {
  float: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faux-text:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #222;
}
</style>
