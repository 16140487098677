export default () => {
  const videos = document.querySelectorAll('.js-video-el')
  if (!videos.length) {
    return false
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const video = entry.target
      try {
        if (entry.intersectionRatio > 0) {
          video.play()
        } else {
          if (!video.paused) {
            video.pause()
          }
        }
      } catch (e) {
        // Ignore playback errors
      }
    })
  }, { threshold: 0.4 })

  videos.forEach(el => {
    observer.observe(el)
  })
}
