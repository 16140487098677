<template>
  <div class="relative">
    <ValidationProvider v-if="includes(['text', 'email', 'tel', 'password', 'search', 'number'], type)" mode='lazy' :vid="name" :name="label" :rules="validation" v-slot="{ errors }" tag="div">
      <input class="relative bg-transparent z-1 input-reset w-100 f5 outline-0 white bb bt-0 bl-0 br-0 br0"
             :style="styles"
             :class="[errors.length ? 'b--red' : !currentValue ? 'b--white' : 'b--green', disabled ? 'o-30' : null]"
             :type="type"
             :placeholder="[validation && validation.includes('required') ? `${placeholder} *` : placeholder]"
             :id="name"
             :autocomplete="autocomplete"
             :value="currentValue"
             :name="name"
             @focus="handleFocus"
             @blur="handleBlur"
             @input="handleInput"
             :readonly="readonly === 'true'"
             :disabled="disabled"
             v-model="currentValue">
      <transition @enter="errorEnter" @leave="errorLeave" :css="false">
      <span v-if="errors.length" class="bg-near-black z-2 red absolute top-4 right-0 pa1 form-error">
        {{ errors[0] }}
      </span>
      </transition>
    </ValidationProvider>
    <ValidationProvider v-if="type === 'textarea'" mode='lazy' :vid="name" :name="label" :rules="validation" v-slot="{ errors }" tag="div">
      <textarea
        :style="styles"
        class="input-reset br0 relative bg-transparent z-1 input-reset w-100 f5 outline-0 white b--white bb bt-0 bl-0 br-0"
        :class="[errors.length ? 'b--red' : !currentValue ? 'b--white' : 'b--green', disabled ? 'o-30' : null]"
        rows="8"
        :placeholder="placeholder"
        :name="name"
        :id="name"
        v-model="currentValue"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        :disabled="disabled"
      ></textarea>
      <transition @enter="errorEnter" @leave="errorLeave" :css="false">
      <span v-if="errors.length" class="bg-near-black z-2 red absolute top-4 right-0 pa1 form-error">
       {{ errors[0] }}
      </span>
      </transition>
    </ValidationProvider>

    <label v-if="label" :for="name">
      {{ label }}
      <span v-if="validation && validation.includes('required')"> *</span>
    </label>
  </div>
</template>

<script>
import _includes from "lodash/includes"
import anime from "animejs"

export default {
  name: "ViempieField",
  data () {
    return {
      currentValue: null
    }
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String
    },
    initialvalue: {
      type: [String, Number]
    },
    autocomplete: {
      type: String
    },
    label: {
      type: String
    },
    scope: {
      type: String
    },
    validation: {
      type: String
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: String,
      default: 'false'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
    }
  },
  mounted () {
    if (!this.initialvalue) return
    this.setCurrentValue(this.initialvalue)
    this.$el.classList.add("float-label")
  },
  watch: {
    currentValue (value) {
      this.$emit("change", value)
    }
  },
  methods: {
    includes: (collection, value) => _includes(collection, value),
    errorEnter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        translateX: [20, 0],
        duration: 400,
        easing: "easeOutCubic",
        complete: done
      })
    },
    errorLeave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 200,
        easing: "easeOutCubic",
        complete: done
      })
    },
    handleFocus (event) {
      this.$emit("focus", event)
    },
    handleBlur (event) {
      this.$emit("blur", event)
    },
    handleInput (event) {
      const value = event.target.value
      this.$emit("input", value)
      this.$emit("change", value)
      this.setCurrentValue(value)

      if (value) {
        this.$el.classList.add("float-label")
      } else {
        this.$el.classList.remove("float-label")
      }
    },
    setCurrentValue (val) {
      if (val === this.currentValue) return
      this.currentValue = val
    }
  },
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  label {
    font-size: 1rem;
    opacity: 0;
    transform: translateY(5px);
    transition: all 200ms;
    position: absolute;
    top: -1.5rem;
    left: 0;
  }

  .float-label label {
    opacity: 0.9;
    transform: translateY(0);
  }
</style>
