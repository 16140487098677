<template>
  <div class="product__purchaser">
    <div class="product__price">
      <div v-if="specifications || pdfUrl" class="product__specification">
        <expandable-text
            v-if="specifications"
            @click.native="scrollListener"
            :description-text="specifications"
            :btn-label-collapsed="_translate('Product specifications')"
            :btn-label-expanded="_translate('Hide product specifications')"
        ></expandable-text>
        <p v-if="pdfUrl" style="margin-bottom: 1rem; margin-top: 1rem;">
          <a class="white-link" :href="pdfUrl" target="_blank">{{ _translate('Installation manual') }}</a>
        </p>
      </div>
    </div>

    <div class="product__customize-title"
         :class="{ 'product__customize-title-with-promo': promo.hasDiscount && promo.custom }"
         ref="customize">{{ computedCustomize }}
    </div>
    <p v-if="promo.hasDiscount && promo.custom" class="product__promo-description product__promo-description-custom">
      {{ computedPromoCustomText }}
    <form method="POST"
          v-if="configurations"
          ref="form"
          accept-charset="UTF-8"
          @submit.prevent="updateCart"
          class="product__form">
      <input type="hidden" name="action" value="commerce/cart/update-cart">
      <input type="hidden" :name="'purchasables['+product.id+'][id]'" :value="currentVariant.id"
             id="inputPurchasableId">
      <input type="hidden" :name="'purchasables['+product.id+'][qty]'" :value="1">

      <product-length v-if="product.type.handle === 'main'"
                      :configurations="configurations"
                      :is-customize="isCustomize"
                      :is-corner-shelf="isCornerShelf"
                      @changeAdditionInfo="changeAdditionInfo"
                      @costChange="costChange"
                      @customLengthCorrect="setCustomLengthCorrect">
      </product-length>

      <product-expand :title="colorTitle"
                      @click.native="scrollListener"
                      :expanded="product.type.handle === 'newAccessories' || selectedOptions.v_color.vSkuModificator === 'CC'"
                      :custom-text="selectedOptions.v_color.vSkuModificator === 'CC' ? store.state.additionInfo.v_color : undefined">
        <product-colors :init-color="color"
                        :colors="colors"
                        :product="product"
                        :sample-pack-url="samplePackUrl"
                        @changeAdditionInfo="changeAdditionInfo"
                        @costChange="costChange"/>
        <product-finish v-if="(product.type.handle === 'main' ||  product.type.handle === 'newAccessories')
                        && filters.v_finish
                        && filters.v_finish.length
                        && isFinishAvailable"
                        :header="_translate('Finish')"
                        @costChange="costChange"
                        :items="store.state.filters.v_finish"/>
        <more-information v-if="descriptions.vfinish"
                          :title="_translate('Finish')"
                          :description="descriptions.vfinish"/>
      </product-expand>

<!--      <product-expand v-if="product.type.handle === 'main' && filters.v_finish-->
<!--                        && filters.v_finish.length-->
<!--                        && isFinishAvailable"-->
<!--                      :title="_translate('Finish')"-->
<!--                      @click.native="scrollListener">-->
<!--        <product-finish @costChange="costChange"-->
<!--                        :items="store.state.filters.v_finish"/>-->
<!--        <more-information v-if="descriptions.vfinish"-->
<!--                          :title="_translate('Finish')"-->
<!--                          :description="descriptions.vfinish"/>-->
<!--      </product-expand>-->

      <product-expand v-if="product.type.handle === 'main' && filters.v_led && filters.v_led.length"
                      :title="_translate('Lighting')"
                      :expanded="isExpanded('v_led')"
                      :notification="_translate('From 50 long and 22 cm deep')"
                      @click.native="scrollListener">
        <product-lightning @costChange="costChange"
                           :product="product"
                           :disabled="disabled.v_led"
                           :disable-all-around="product.id === 60505"
                           :disable-top-and-bottom="hasSelectedCornerShelf" />
        <more-information v-if="descriptions.vled"
                          :title="_translate('Lighting')"
                          :description="descriptions.vled"/>
      </product-expand>

      <product-expand v-if="
                      product.type.handle === 'main' && (
                          isCornerShelf ||
                          (filters.v_cutouts && cutouts.length) ||
                          (filters.v_corners && filters.v_corners.length) ||
                          (filters.v_corner_shelf && cornerShelf)
                      )"
                      :title="_translate('Customization')"
                      :expanded="hasCustomizations || isCornerShelf"
                      @click.native="scrollListener">

        <product-customization v-if="product.type.handle === 'main' && filters.v_corners && filters.v_corners.length"
                               :title="_translate('Corners')"
                               @click.native="scrollListener">
          <product-corners @costChange="costChange"
                           :items="store.state.filters.v_corners"/>
          <more-information v-if="descriptions.vcorners"
                            :title="_translate('Corners')"
                            :description="descriptions.vcorners"/>
        </product-customization>

        <product-customization v-if="product.type.handle === 'main' && filters.v_cutouts && cutouts.length > 0"
                        :title="_translate('Add-ons')"
                        :notification="computedCutoutsNotification"
                        @click.native="scrollListener">
          <product-cut-outs @costChange="costChange"
                            :disabled="isCutoutsDisabled"
                            :items="cutouts"/>
          <more-information v-if="descriptions.vcutOuts"
                            :title="_translate('Add-ons')"
                            :description="descriptions.vcutOuts"/>
        </product-customization>

        <product-customization v-if="product.type.handle === 'main' && filters.v_corner_shelf && cornerShelf"
                               :title="_translate('Corner shelf')"
                               :notification="cornerShelfNotification"
                               @click.native="scrollListener">
          <product-corner-shelf
                            @costChange="costChange"
                            @changeAdditionInfo="changeAdditionInfo"
                            @cornerShelfCorrect="setCornerShelfCorrect"
                            :descriptionTitle="_translate('Corner shelf')"
                            :description="descriptions.vCornerShelf"
                            :disabled="isCornerShelfDisabled"
                            :item="cornerShelf"
                            :isStatic="isCornerShelf"/>
        </product-customization>

      </product-expand>

      <div v-if="isSamplesProductsInTheCart"
        class="note-warning"
      >
        <strong>
        {{ _translate('Note: Color swatches cannot be ordered at the same time as other products. For that, please place a new order.') }}
        </strong>
      </div>
      <button
          ref="button"
          class="cf w-100 product__add-to-cart
          pointer input-reset f5 ls-05 bw0 bg-yellow sans-serif fw7 pa3 pa4-xl dib outline-0 br1 dim mb4"
          :class="{'product__add-to-cart_absolute': isLoaded}"
          :disabled="isButtonDisabled"
      >
        <span class="fl w-50 tl">{{ _translate('Add to cart') }}</span>
        <span class="fr">{{ computedCost|toEur }}</span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../store'
import h from '../helpers'
import ViempieField from '../components/ViempieField.vue'
import ExpandableText from './ExpandableText.vue'
import ProductLength from './Product/ProductLength.vue'
import ProductExpand from './ProductExpand.vue'
import ProductColors from './ProductColors.vue'
import ProductLightning from './ProductLightning.vue'
import ProductCutOuts from './ProductCutOuts.vue'
import ProductCorners from './ProductCorners.vue'
import ProductRoomDivider from './ProductRoomDivider.vue'
import MoreInformation from './MoreInformation.vue';
import { reload } from './ProductImagesSlider';
import ProductFinish from './ProductFinish.vue';
import ProductCustomization from './ProductCustomization.vue';
import ProductCornerShelf from './ProductCornerShelf.vue';
import s from "../store";

export default {
  name: 'Purchaser',
  components: {
    ProductFinish,
    MoreInformation,
    ViempieField,
    ExpandableText,
    ProductLength,
    ProductExpand,
    ProductColors,
    ProductLightning,
    ProductCutOuts,
    ProductCorners,
    ProductRoomDivider,
    ProductCustomization,
    ProductCornerShelf
  },
  data() {
    return {
      store: store,
      currentVariant: null,
      windowWidth: window.innerWidth,
      configurations: null,
      isCustomize: false,
      isLoaded: false,
      isCustomLengthCorrect: true,
      isCornerShelfCorrect: true,
    }
  },
  watch: {
    configurations() {
      if (!store.isMobile()) {
        setTimeout(() => {
          this.scrollListener()
          this.stickyComponent()
        }, 0)
      }

      setTimeout(() => {
        if (this.isCustomize) {
          const customize = this.$refs.customize
          const headerHeight = document.querySelector('#js-header > div').getBoundingClientRect().height
          const offset = 0

          window.scrollTo({
            top: customize.getBoundingClientRect().top - headerHeight + offset,
            left: 0,
          })
        }
      }, 50)
    }
  },
  props: {
    product: {type: Object},
    accessories: {type: Array},
    disabled: {type: Object},
    accessoryImages: {type: Object},
    descriptions: {},
    variants: {type: Array},
    initVariantId: {type: Number, required: true},
    category: {type: String, default: ''},
    color: {type: Object, required: true},
    colors: {required: true},
    currency: {type: String, default: 'EUR'},
    pdfUrl: {type: String},
    samplePackUrl: {type: String},
    filters: {type: Object, default: {}},
    similarDiscounts: {type: String},
    specifications: {type: String},
    promo: {},
  },
  beforeMount() {
    const productSlug = `${this.product.slug}--${this.color.slug}`;
    this.currentVariant = this.variants.find(variant => variant.id === this.initVariantId)

    axios.get(`/products/${productSlug}/configurations`).then(({data}) => {
      this.configurations = data;
      store.setDefaults(this.configurations)
      const types = ['main'];
      if (types.includes(this.product.type.handle)) {
        if (window.location.hash.substring(1)) {
          try {
            store.state.additionInfo = JSON.parse(decodeURI(window.location.hash.substring(1)))
          } catch (e) {
            if (window.location.hash.substring(1) === 'customize') {
              this.isCustomize = true
            }
          }
        }
        for (let prop in store.state.defaults) {
          if (this.filters.hasOwnProperty(prop)) {
             this.costChange(prop, this.filters[prop].find(item => item.id === this.configurations[prop]), false)
          }
        }
        this.selectParams()
      }
    });
    store.setFilters(this.filters)
    this.accessories.forEach((item) => {
      item.images = this.accessoryImages[item.id];
    });
    store.setAccessories(this.accessories)
    Object.keys(this.filters).map(key => this.costChange(key, {}, false))
    this.costChange('v_color', this.color, false)
  },
  methods: {
    setCustomLengthCorrect(isCorrect) {
      this.isCustomLengthCorrect = isCorrect;
    },
    setCornerShelfCorrect(isCorrect) {
      this.isCornerShelfCorrect = isCorrect
    },
    isExpanded(property = 'v_cutouts') {
      return this.disabled.hasOwnProperty(property) && this.disabled[property] !== 0
    },
    stickyComponent() {
      window.addEventListener('scroll', this.scrollListener)
    },
    scrollListener() {
      if (store.isMobile()) {
        return
      }
      const button = this.$refs.button
      const stickyContainer = document.querySelector('.product__purchaser')
      let offset = 0

      if (stickyContainer !== null && typeof button !== typeof undefined) {
        offset = stickyContainer.getBoundingClientRect().height - button.getBoundingClientRect().height

        if (window.innerHeight < stickyContainer.getBoundingClientRect().height + stickyContainer.getBoundingClientRect().top) {
          offset = window.innerHeight - stickyContainer.getBoundingClientRect().top - button.getBoundingClientRect().height
        }
      }

      if (typeof button !== typeof undefined) {
        button.style.top = `${offset}px`;
        this.isLoaded = true
      }
    },
    selectParams() {
      const searchParams = new URL(location.href).searchParams
      let hashParams = new URL(location.href).hash.split('#')[1]
      if (hashParams !== undefined) {
        hashParams = JSON.parse(decodeURIComponent(hashParams))
      }

      searchParams.delete('gclid')
      const requiredProperties = ['v_len', 'v_depth']

      if (searchParams.toString() !== '') {
        searchParams.forEach((id, part) => {
          if (this.filters.hasOwnProperty(part)) {
            let item = this.filters[part].find(item => +item.id === +id)
            this.costChange(part, item, false)
          }
        })
        requiredProperties.forEach((property) => {
          if (typeof searchParams.get(property) === typeof null) {
            let item
            if (property === 'v_len') {
              item = this.filters.v_len.filter(len => len.id === this.configurations.v_len)[0]
            }
            if (property === 'v_depth') {
              item = this.filters.v_depth.filter(depth => depth.id >= this.configurations.v_depth)[0];
            }

            if (item) {
              this.costChange(property, item, false)
            }
          }
        })
      } else {
        // const defaultSelectedLength = 110;
        // const defaultSelectedDepth = 29.5;
        Object.keys(this.filters).forEach((key) => {
          let item = this.filters[key].find(item => +item.vPriceModificator === 0)
          if (key === 'v_len') {
            item = this.filters.v_len.filter(len => !len.vMaxLen && len.id >= this.configurations.v_len)[0];
          }
          if (key === 'v_depth') {
            item = this.filters.v_depth.filter(depth => depth.id >= this.configurations.v_depth)[0];
          }
          if (typeof item === 'undefined')
            // Skip 'v_color' as it's already provided in the current slug
            if (key !== 'v_color') {
              item = this.filters[key][0]
              this.costChange(key, item, true)
            }
        })
      }

      Object.keys(this.disabled).forEach((key) => {
        const item = this.filters[key].find((item) => item.id === this.disabled[key])
        this.costChange(key, item, true)
      })

      this.costChange('v_color', this.color, false)
    },
    _translate(key, ...params) {
      let translation = store._translates(key)
      if (params.length) {
        for (const param of params) {
          translation = translation.replace('%s', param)
        }
      }
      return translation
    },
    costChange(type, item, changeUrl = true) {
      // Change shown(active) images on color change/mount page
      const shownClass = 'product__color-images_shown';
      const element = document.querySelector(`.product__color-images.${shownClass}`);

      if (type === 'v_corner_shelf') {
        store.setSelectedOption('v_corner_shelf', item)
        if (changeUrl) this.updateUrl(type, item)
        return;
      }

      if (!item || (Object.keys(item).length === 0 && item.constructor === Object)) {
        return;
      }

      if (type === 'v_color' && element) {
        const selectedColorWrapper = document.querySelector(`[data-color=${item.slug}]`);
        if (!selectedColorWrapper) {
          return false;
        }

        element.classList.remove(shownClass);
        element.querySelectorAll('.product__images-item').forEach((image) => {
          image.classList.remove('product__images-item_shown');
        });
        selectedColorWrapper.classList.add(shownClass);
        const images = selectedColorWrapper.querySelectorAll('.product__images-item');
        images.forEach((image) => {
          image.classList.add('product__images-item_shown');
        });

        reload();
      }

      if (type === 'v_color' && changeUrl) {
        const finish = this.filters.v_finish.find(finish => finish.vPriceModificator === 0);
        store.setSelectedOption('v_finish', finish);
      }

      if (type === 'v_len') {
        // Charge for extra length(if length >150, +50 eur)
        const length = item.vMinLen;
        const selectedLED = store.getSelectedOption('v_led')

        store.state.filters.v_led.forEach((led => {
          if (led.ledSpecific.length == 0) {
            return;
          }

          let found = false;
          let currentSpecific = [];
          for (const specific of led.ledSpecific)
          {
            if ((found = length >= specific.minLength && length <= specific.maxLength))
            {
              currentSpecific = specific;
              break;
            }
          }

          if (!found) {
            return;
          }

          led.vPriceModificator = currentSpecific.priceModificator;
          if (selectedLED && selectedLED.id === led.id) {
            selectedLED.vPriceModificator = led.vPriceModificator
          }
        }))
      }

      if (type === 'v_led') {
        if (!item) {
          return false;
        }

        const vAvailPositionExist = typeof item.vAvailPosition !== 'undefined';

        // Remove/skip cable position to null if item doesn't have available cable vAvailPosition
        if (!vAvailPositionExist || vAvailPositionExist && item.vPriceModificator === 0) {
          store.setAdditionInfo('v_led', null);
        }

        // Add default cable position("left") only if avail position actually exist & price more than zero ᕙ༼ຈل͜ຈ༽ᕗ
        if (vAvailPositionExist && item.vPriceModificator > 0) {
          store.setAdditionInfo('v_led', 'position:left');
        }

        store.setSelectedOption('v_lighting_plan', null);
        if (item.vPriceModificator <= 0) {
          store.setSelectedOption('v_control_system', null);
        } else {
          if (store.state.filters.v_control_system) {
            const defaultControlSystem = store.state.filters.v_control_system.filter(el => el.vPriceModificator === 0)[0];
            store.setSelectedOption('v_control_system', defaultControlSystem);
          }
        }
      }

      if (type === 'v_control_system') {
        if ('Strackk' === item.title) {
          store.setSelectedOption('v_lighting_plan', null);
        } else {
          const selectedLightingPLan = store.getSelectedOption('v_lighthing_plan');
          if (!selectedLightingPLan) {
            const defaultLigthingPlan = store.state.filters.v_lighting_plan[0];
            store.setSelectedOption('v_lighting_plan', defaultLigthingPlan);
          }
        }
      }

      if (typeof item !== 'undefined' && item.id) {
        if (type === 'v_lighting_plan') {
          const selectedControlSystem = store.getSelectedOption('v_control_system');
          if (selectedControlSystem === undefined || selectedControlSystem.title === 'Strackk') {
            return;
          }
        }

        store.setSelectedOption(type, item)
        if (changeUrl) {
          this.updateUrl(type, item)
        }
      }

      if (window.location.pathname.indexOf('room-divider') !== -1) {
        if (type === "v_len" || type === "v_depth") {
          const roomDivider = this.filters.v_room_divider.find(roomDivider => roomDivider.slug !== "none")
          store.setSelectedOption("v_room_divider", roomDivider)
        }
      }
    },
    updateUrl(type, item = undefined) {
      let sp = new URL(location.href).searchParams
      if (item) {
        sp.set(type, item.id)
      } else {
        sp.delete(type);
      }
      const searchParams = sp.toString() === '' ? '' : `?${sp.toString()}`
      const hash = JSON.stringify(store.state.additionInfo) === '{}' ? '' : `#${JSON.stringify(store.state.additionInfo)}`
      window.history.pushState(
          store.state.selectedOptions,
          window.document.title,
          window.location.origin
          + window.location.pathname.replace(/--(.*)/, `--${store.getSelectedOption('v_color').slug}`)
          + `${searchParams}${hash}`
      )
      if (item && type === 'v_color') {
        const pathParts = window.location.pathname.split('/')
        this.currentVariant = this.variants.find(variant => variant.vSlug === pathParts[pathParts.length - 1])
        setTimeout(() => {
          this.scrollListener()
        }, 0)
      }
    },
    changeAdditionInfo(type, val) {
      store.setAdditionInfo(type, val)
      this.updateUrl(type)
    },
    getIndex(key, arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].purchasableId === key) {
          return i
        }
      }
    },
    updateCart() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'btnClick',
          targetPage: '/added_to_cart',
        })
      }

      const form = this.$refs.form
      let data = h.getFormData(form, store.state.csrf)
      Object.keys(store.state.selectedOptions).map(key => {
        if (typeof undefined !== typeof store.state.selectedOptions[key]) {
          data.append(`purchasables[${this.product.id}][options][${key}]`,
              JSON.stringify({
                id: store.state.selectedOptions[key].id,
                price: store.state.selectedOptions[key].vPriceModificator,
              })
          )
        }
      })
      if (JSON.stringify(store.state.additionInfo) !== '{}') {
        data.append(`purchasables[${this.product.id}][options][addition]`, JSON.stringify(store.state.additionInfo))
      }
      // Post request
      const settings = {
        url: '/',
        data,
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }

      const locale = document.documentElement.getAttribute('lang')

      axios(settings)
          .then(() => {
            axios
                .get('/' + locale + '/api/v1/cart.json')
                .then(response => {
                  const data = response.data.data[0]
                  // Put the whole thing into the state
                  store.setCartAction(data)
                  store.toggleSideCartAction()
                  // Add event to dataLayyer, e.g. for Pinterest
                  let lineItems = data.cart.lineItems
                  let orderQty = Object.values(lineItems).filter(lineItem => lineItem.variant.id === this.currentVariant.id)[0].qty;
                  if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                      'event': 'addProductToCart',
                      'productID': this.product.id,
                      'productValue': this.computedCost,
                      'product_variant_id': this.currentVariant.id,
                      'product.variant_id': this.currentVariant.id,
                      'product_category': this.category,
                      'product.category': this.category,
                      'order_quantity': orderQty,
                      'currency': this.currency,
                    })
                  }
                  window.waitForFbqReady(() => {
                    window.fbq('track', 'AddToCart', {
                      content_category: this.category,
                      content_type: 'product',
                      content_name: this.currentVariant.title,
                      content_ids: [this.currentVariant.id],
                      currency: this.currency,
                      value: this.computedCost
                    });
                  });
                })
          })
          .catch(function (error) {
            if (store.debug) console.log(error)
          })
      s.setCsrf();
    },
    updateDiscountText() {
      // if (this.privateState.similarDiscounts.hasDiscount) {
      //   this.privateState.similarDiscountText = this.privateState.similarDiscounts.text
      // }
    },
    setDefault(type) {
      if (this.configurations.hasOwnProperty(type) && this.filters.hasOwnProperty(type)) {
        this.costChange(type, this.filters[type].find(item => item.id === this.configurations[type]))
      }
    },
    lazyLoad(selector, imageUrl) {
      let backgroundImageUrl = `url('${imageUrl}')`;
      var lazyBackgrounds = [].slice.call(document.querySelectorAll(selector));
      if ("IntersectionObserver" in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              entry.target.style.backgroundImage = backgroundImageUrl
              lazyBackgroundObserver.unobserve(entry.target);
            }
          });
        });

        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    }
  },
  computed: {
    isSamplesProductsInTheCart() {
      return store.state.sampleProductsInCart > 0
    },
    isButtonDisabled() {
      if (!this.isCustomLengthCorrect || !this.isCornerShelfCorrect) {
        return true;
      }

      return this.isSamplesProductsInTheCart || (store.getSelectedOption('v_color').vSkuModificator === 'CC'
          && !store.state.additionInfo.hasOwnProperty('v_color'))
    },
    isFinishAvailable() {
      let isFinishAvailable = store.getSelectedOption('v_color').vPriceModificator === 0
          || store.getSelectedOption('v_color').vSkuModificator === 'CC';

      // Check additional info of a color from palette.
      // Currently, specials are those which do not start with ral.
      let vColor = store.getAdditionInfo('v_color');
      if (vColor) {
        isFinishAvailable = isFinishAvailable && vColor.toLowerCase().startsWith('ral');
      }

      return isFinishAvailable
    },
    availableDepth() {
      let depths = store.state.filters.v_cutouts.filter((cutout) => cutout.vMinDepth !== null)
      depths = depths.map((cutout) => cutout.vMinDepth)
      return Math.min(...depths)
    },
    computedCutoutsNotification() {
      return this._translate(`Only at {depth}cm depth`).replace('{depth}', this.availableDepth)
    },
    computedCustomize() {
      const phrase = this.product.type.handle === 'newAccessories' ? 'Customize your accessory' : 'Customize your shelf'

      return this._translate(phrase)
    },
    computedPromoCustomText() {
      return this._translate(this.promo.customizePromoText)
    },
    colorTitle() {
      return this._translate('Color')
    },
    roomDividers() {
      let none = this.filters.v_room_divider.sort((a, b) => a.vPriceModificator > b.vPriceModificator && 1 || -1)[0]
      // ToDo Bug with slug translates
      let list
      const vLedUID = 'f84327fb-a657-474f-8214-f6779de002e8'
      const vCutoutsUID = '55ddb062-717c-474c-aea8-aeafdcfb2b53'
      // lights-underneath and Cut-outs for clothing hangers
      if ([vLedUID, vCutoutsUID].indexOf(+store.getSelectedOption('v_led').uid) === vLedUID
          || +store.getSelectedOption('v_cutouts').uid === vCutoutsUID) {
        list = []
      } else {
        list = store.state.filters.v_room_divider.filter(divider =>
            divider.vMinDepth <= store.getSelectedOption('v_depth').vDepth &&
            divider.vMinLen <= store.getSelectedLengthVal()
        )
      }

      if (list.length < 2 && store.getSelectedOption('v_room_divider').id !== none.id) {
        this.costChange('v_room_divider', none)
      }
      return list
    },
    cutouts() {
      let list = store.state.filters.v_cutouts.filter(cutout => {
            const option = store.getSelectedOption('v_depth');

            if (!option || !option.hasOwnProperty('vDepth')) {
              return false
            }

            return cutout.vMinDepth <= option.vDepth &&
                cutout.vMinLen <= store.getSelectedLengthVal()
          }
      )
      if (store.state.selectedOptions.hasOwnProperty('v_cutouts')) {
        if (!list.find(item => item.id === store.getSelectedOption('v_cutouts').id)) {
          this.costChange('v_cutouts', this.filters.v_cutouts.sort((a, b) => a.vPriceModificator > b.vPriceModificator && 1 || -1)[0])
        }
      }

      return list
    },
    isCutoutsDisabled() {
      return this.disabled.v_cutouts ? 1 : 0
    },
    cornerShelf() {
      if (!store.getSelectedOption('v_depth') || !store.getSelectedOption('v_depth').hasOwnProperty('vDepth')) {
        return null;
      }

      const config = this.product.configuration;

      // Enable only for simple shelves and shelves with lightning
      if (
          this.product.type.handle !== 'main' ||
          (config.cutouts.length && config.roomdivider[0] !== 58503)
      ) {
        return null;
      }

      return Array.isArray(store.state.filters.v_corner_shelf) ?
          store.state.filters.v_corner_shelf[0] :
          null
    },
    isCornerShelfDisabled() {
      const length = Number(store.getSelectedLengthVal())
      const led = store.getSelectedOption('v_led')

      const isTopAndBottom = led && led.id && (led.id === 257993 || led.id === 244681)

      return this.disabled.v_corner_shelf ||
          length < this.cornerShelf.vMinLen ||
          length > this.cornerShelf.vMaxLen ||
          isTopAndBottom? 1 : 0
    },
    cornerShelfNotification() {
      if (!this.cornerShelf) {
        return null;
      }

      const length = Number(store.getSelectedLengthVal())

      if (length < this.cornerShelf.vMinLen) {
          return this._translate('Starting at %scm long', this.cornerShelf.vMinLen)
      } else if (length > this.cornerShelf.vMaxLen) {
          return this._translate('Up to %scm long', this.cornerShelf.vMaxLen)
      }

      return null
    },
    hasCustomizations() {
      if (this.filters.v_corner_shelf && this.cornerShelf && store.state.selectedOptions.v_corner_shelf) {
        return true
      }

      if (store.state.selectedOptions.hasOwnProperty('v_cutouts')) {
        const cutout = this.filters.v_cutouts.find(item => item.id === store.getSelectedOption('v_cutouts').id)
        if (cutout && cutout.vPriceModificator > 0) {
          return true
        }
      }

      if (
          store.state.selectedOptions.hasOwnProperty('v_corners') &&
          store.getSelectedOption('v_corners').vPriceModificator > 0
      ) {
        return true
      }

      return false
    },
    hasSelectedCornerShelf() {
      return this.isCornerShelf || store.state.selectedOptions.v_corner_shelf;
    },
    selectedOptions() {
      return store.state.selectedOptions
    },
    additionInfo() {
      return store.state.additionInfo
    },
    computedCost() {
      let cost = this.product.type.handle === 'main' ? 0 : this.currentVariant.price
      for (const key in store.state.selectedOptions) {
        if (typeof undefined !== typeof store.getSelectedOption(key)
            && store.getSelectedOption(key).hasOwnProperty('vPriceModificator')) {
          if (this.product.type.handle !== 'main' && key === 'v_color') {
            continue
          }

          cost += store.getSelectedOption(key).vPriceModificator
        }
      }

      return cost
    },
    isMobile: function () {
      return this.windowWidth < 920;
    },
    isCornerShelf: function() {
      return this.product.configuration.cornershelf &&
          this.product.configuration.cornershelf.length > 0
    }
  },
  mounted() {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        'event': 'viewItem',
        'productID': this.product.id,
        'currency': this.currency,
        'product_category': this.category,
        'product_name' : this.currentVariant.title,
        'product_variant_id': this.currentVariant.id,
        'product.variant_id': this.currentVariant.id,
        'product_price': this.currentVariant.price
      });
    }

    window.waitForFbqReady(() => {
      window.fbq('track', 'ViewContent', {
        content_type: 'product',
        content_name: this.currentVariant.title,
        content_ids: [this.currentVariant.id],
        currency: this.currency,
        value: this.currentVariant.price
      });
    });

    if (store.state.selectedOptions.v_corner_shelf && (!this.filters.v_corner_shelf || !this.cornerShelf)) {
      this.costChange('v_corner_shelf', undefined, true)
    }

    if (this.isCornerShelf && Array.isArray(store.state.filters.v_corner_shelf)) {
      setTimeout(() => {
        this.costChange('v_corner_shelf', store.state.filters.v_corner_shelf[0]);
      })
    }

    this.lazyLoad('.product__filter-lazy', "/build/assets/loader.gif")
    this.$parent.$refs.loader.classList.add('product__filter--loader_hide');
    this.$parent.$refs.filter.classList.add('product__filter--wrapper_show');
  },
  updated() {
    this.scrollListener()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.note-warning {
  margin-bottom: 1rem;
  color: #ffd600;
}

@media screen and (min-width: 60em) {
  .product {
    &__form {
      padding-bottom: 66px;
      margin-bottom: 2rem;
    }

    &__purchaser {
      position: relative;
    }

    &__add-to-cart {
      top: 0;

      &_absolute {
        position: absolute;
      }
    }
  }
}

@media screen and (max-width: 60em) {
  .product__add-to-cart,
  .product__add-to-cart * {
    color: #0a0d0f !important;
  }
  .product__add-to-cart:disabled, .product__add-to-cart[disabled] {
    color: #6d6d6d!important;
    span {
      color: #6d6d6d!important;
    }
  }

  .product__v_color-wrapper {
    justify-content: flex-start !important;
    flex-wrap: wrap;

    .color-radio__button {
      padding: 0.6875rem;
    }
  }
}
</style>
