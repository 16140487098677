<template>
  <div>
    <div class="product__v_finish-header">{{ _translate('Finish') }}</div>
    <div class="product__v_finish">
      <div v-for="finish in items"
           :key="finish.id"
           class="product__finish"
           @click="changeValue(finish)"
           :class="{'product__finish_active': isActive(finish.id)}">
        <div class="product__finish-image expand__option"
             :style="computedStyle(finish)"></div>
        <div class="product__finish-title">{{ finish.title }}</div>
        <div class="product__finish-cost">{{ finish.vPriceModificator | toEur }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import {eventBus} from "../eventBus"
import {translatesMixin} from "../mixins/translatesMixin";
export default {
  name: "ProductFinish",
  mixins: [translatesMixin],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    store: store,
    pickedColorSlug: ''
  }),
  mounted() {
    this.pickedColorSlug = store.getSelectedOption('v_color').slug
  },
  created() {
    eventBus.$on('color-changed', color => {
      this.pickedColorSlug = color.slug
    })
  },
  computed: {
    isOtherColor: function() {
      return this.pickedColorSlug === 'other-color'
    }
  },
  methods: {
    isActive(finishId) {
      if (store.getSelectedOption().hasOwnProperty('v_finish')) {
        return store.getSelectedOption('v_finish').id === finishId
      }

      return false
    },
    changeValue(finish) {
      this.$emit('costChange','v_finish',finish)
    },
    image (finish) {
      if (finish.cover.length) return finish.cover[0]
      return ''
    },
    computedStyle (finish) {
      return {
        backgroundImage: `url('${this.image(finish)}')`,
        backgroundPosition: 'right bottom',
        backgroundSize: 'cover'
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .product {
    &__finish {
      flex-basis: 100%;
      cursor: pointer;
      text-align: center;

      &-image {
        border-radius: 2px;
        border: solid 2px #333333;
        height: 80px;
        margin-bottom: 1rem;
        transition: 0.3s;
      }

      &-title {
        line-height: 1.5;
        color: #fff;
        transition: 0.3s;
      }

      &-cost {
        font-size: 14px;
      }

      &:hover {
        .product {
          &__finish {
            &-image {
              border-color: #616161;
            }

            &-title {
              color: #fff;
            }
          }
        }
      }

      &_active {
        .product__finish {
          &-image {
            border-color: #ffd600;

            &:hover {
              border-color: #ffd600;
            }
          }

          &-title {
            color: #fff;
          }
        }
      }
    }

    &__v_finish {
      display: flex;
      gap: 1.5rem;

      &-header {
        font-family: Ladislav, serif;
        font-size: 1.5rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff;
        padding: 2rem 0 1rem;
        position: relative;
        display: flex;
        align-items: center;
      }
    }
  }
</style>
