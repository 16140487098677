<template>
  <ul class="list pa0 ma0 mb3">
    <label :for="gatewayFor(gateway.id)" v-for="gateway in gatewaysList" :key="gateway.id">
      <li class="mb2 list-item">
        <input
          type="radio"
          :id="gatewayFor(gateway.id)"
          name="gatewayId"
          v-model="gatewayId"
          :value="gateway.id"
          class="mr3"
        />
        <span>{{ gateway.label }}</span>
      </li>
    </label>
  </ul>
</template>

<script>
export default {
  name: 'GatewaysSelector',
  props: ['gateways'],
  data() {
    return {
      gatewayId: 0,
    };
  },
  computed: {
    gatewaysList() {
      return this.gateways.map((gateway) => ({
        id: Number(gateway.data.id),
        label: gateway.label,
      }));
    },
  },
  methods: {
    gatewayFor(id) {
      return 'gateway_' + id
    }
  },
  mounted() {
    const gateways = this.gatewaysList;
    this.gatewayId = gateways.length ? gateways[0].id : 0;
  },
};
</script>