<template>
  <label class="checkbox__button">
    <input class="checkbox__input"
           type="checkbox"
           :value="value"
           @input="$emit('input', $event.target.value)"/>
    <div class="checkbox__helper">
      {{ title }}
    </div>
  </label>
</template>

<script>
export default {
  name: "CheckboxComponent",
  props: {
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.checkbox {
  &__indicator_new {
    margin-left: 0.5rem;
    color: #ffd600;
  }

  &__button {
    cursor: pointer;
    margin-bottom: 0.5rem;
    display: inline-block;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover .checkbox__helper {
      color: #ffffff;

      &::before {
        border-color: transparent;
        background: #ffd700;
      }
    }

    &.short {
      width: 40%;
    }
  }

  &__helper {
    height: 1.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    transition: 0.3s;

    &::before {
      content: '';
      margin-right: 1rem;
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 0.125rem;
      background: transparent;
      border: 0.125rem solid #b2b2b2;
      transition: 0.3s;
    }
  }

  &__input {
    display: none;

    &:checked + .checkbox__helper {
      color: #ffffff;

      &::before {
        border-color: transparent;
        background: #ffd700;
      }
    }
  }
}
</style>
