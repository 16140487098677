<template>
</template>

<script>
import s from "../store"

export default {
  name: "StateLoader",
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  beforeMount () {
    s.loadData(this.data)
  }
}
</script>
<style lang="scss" scoped>
</style>
