<template>
  <div class="addresses-form__radio-buttons">
    <div v-if="!showBusinessOnly" class="addresses-form__radio-button">
      <radio-button title="Private"
                    v-model="isPrivate"
                    :value="true"
                    name="isForBusiness" />
    </div>

    <div id="optionBusiness" class="addresses-form__radio-button">
      <radio-button title="For business"
                    v-model="isPrivate"
                    :value="false"
                    name="isForBusiness" />
    </div>
  </div>
</template>

<script>
import RadioButton from "./RadioButton.vue";
export default {
  components: {RadioButton},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    businessOnly: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isPrivate: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    showBusinessOnly() {
      return this.businessOnly === 1;
    }
  },
  mounted() {
    if (this.showBusinessOnly) {
      this.isPrivate = false;
    }
  }
}
</script>