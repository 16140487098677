// imports
import Vue from 'vue'
import FilterComponent from './components/FilterComponent.vue'
import ProductList from './components/ProductList.vue'
import MainProductComponent from './components/MainProductComponent.vue'
import ColorRadioButton from './components/ColorRadioButton.vue'
import ProductColor from './components/ProductColor.vue'
import ProductImage from './components/ProductImage.vue'
import LoginForm from './components/LoginForm.vue'
import RegistrationForm from './components/RegistrationForm.vue'
import AddressesComponent from './components/AddressesComponent.vue'
import CheckoutCart from './components/CheckoutCart.vue'
import LinkUpper from './components/LinkUpper/LinkUpper.vue'
import LangLinkModifier from './components/LangLinkModifier.vue'
import StateLoader from './components/StateLoader.vue'
import RadioButton from './components/RadioButton.vue'
import RadioButtonExtended from './components/RadioButtonExtended.vue'
import ExpandableDescription from './components/ExpandableDescription.vue'
import GatewaysSelector from './components/GatewaysSelector.vue'
import CustomerBlock from './components/CustomerBlock.vue'
import ColorSamples from './components/ColorSamples/ColorSamples.vue'
import ColorSamplesSelector from './components/ColorSamples/ColorSamplesSelector.vue'
import ColorSamplesFilters from './components/ColorSamples/ColorSamplesFilters.vue'
import ColorSamplesProducts from './components/ColorSamples/ColorSamplesProducts.vue'
import ColorSamplesVariant from './components/ColorSamples/ColorSamplesVariant.vue'
import PrivateAndBusinessRadioButtons from './components/PrivateAndBusinessRadioButtons.vue'

// component register
Vue.component('PrivateAndBusinessButtons', PrivateAndBusinessRadioButtons)
Vue.component('FilterComponent', FilterComponent)
Vue.component('ProductList', ProductList)
Vue.component('MainProductComponent', MainProductComponent)
Vue.component('ColorRadioButton', ColorRadioButton)
Vue.component('ProductColor', ProductColor)
Vue.component('ProductImage', ProductImage)
Vue.component('LoginForm', LoginForm)
Vue.component('RegistrationForm', RegistrationForm)
Vue.component('AddressesComponent', AddressesComponent)
Vue.component('CheckoutCart', CheckoutCart)
Vue.component('LinkUpper', LinkUpper)
Vue.component('LangLinkModifier', LangLinkModifier)
Vue.component('StateLoader', StateLoader)
Vue.component('RadioButton', RadioButton)
Vue.component('RadioButtonExtended', RadioButtonExtended)
Vue.component('ExpandableDescription', ExpandableDescription)
Vue.component('GatewaysSelector', GatewaysSelector)
Vue.component('CustomerBlock', CustomerBlock)
Vue.component('ColorSamples', ColorSamples)
Vue.component('ColorSamplesProducts', ColorSamplesProducts)
Vue.component('ColorSamplesSelector', ColorSamplesSelector)
