<template>
  <div class="cart__accessories">
    <div class="cart__accessories-title">Must have accessories</div>
    <div class="cart__accessories-list">
      <accessory-component v-for="item in accessories"
                           :key="item.id"
                           :item="item"/>
    </div>
  </div>
</template>

<script>
import AccessoryComponent from "./AccessoryComponent.vue";
import store from '../store'
export default {
  name: "AccessoriesComponent",
  components: {
    AccessoryComponent,
  },
  props: {
    accessories: {
      type: [],
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
  .cart__accessories {
    text-align: left;
    margin: 0 4.5rem 0 4rem;
    padding-bottom: 5rem;

    @media screen and (max-width: 60em) {
      margin: 0 1rem;
      padding-top: 7.5rem;
    }

    &-list {
      margin-top: 2.5rem;
    }

    &-title {
      font-family: Ladislav,serif;
      font-size: 1.5rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
</style>
