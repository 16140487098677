import Intense from './custom-intense-images'
import './style.scss'

const ProductZoom = options => {
  const els = document.querySelectorAll(options.el)
  if (els.length < 1) return
  if (window.matchMedia('(min-width: 60em)').matches) {
    Intense(els)
  }
}

export default ProductZoom
