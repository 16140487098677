<template>
  <div class="voucher__wrapper">
    <span class="voucher__button" v-if="!isShowed" @click="toggleVoucher">
      {{ _translate('Voucher Code') }}?
    </span>
    <form v-else
          method="post"
          ref="form"
          @submit.prevent="submit"
          accept-charset="UTF-8">
      <input type="hidden" name="action" value="commerce/cart/update-cart"/>
      <div class="voucher__row">
        <input type="text"
               v-model="selfCoupon"
               name="couponCode"
               :readonly="isLoading"
               :placeholder="_translate('Voucher Code')"
               class="voucher__input"
        />
        <button :disabled="!selfCoupon || isLoading"
                type="submit"
                class="voucher__button"
                :class="{'voucher__button_loading': isLoading}">
          {{ _translate('Apply') }}
        </button>
      </div>
      <div v-if="isApplied" class="voucher__message">
          {{_translate('Voucher applied') }}
      </div>
      <div v-if="isNotFound" class="voucher__message voucher__message_warning">
          {{_translate('Voucher is not found') }}
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store.js';

export default {
  name: 'VoucherComponent',
  data() {
    return {
      isShowed: false,
      selfCoupon: null,
      isNotFound: false,
      isApplied: false,
      isLoading: false,
    }
  },
  props: {
    coupon: {
      type: String | null,
      required: true,
    },
  },
  mounted() {
    this.selfCoupon = this.coupon

    if (this.selfCoupon) {
      this.isShowed = true
      this.isApplied = true
    }
  },
  methods: {
    toggleVoucher() {
      this.isShowed = !this.isShowed
    },
    _translate(key) {
      return store._translates(key)
    },
    async submit() {
      this.isLoading = true
      this.isApplied = false
      this.isNotFound = false

      const form = this.$refs.form
      const formData = new FormData(form)
      formData.append(store.state.csrf.tokenName, store.state.csrf.tokenValue)

      const settings = {
        url: '/',
        data: formData,
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }

      const locale = document.documentElement.getAttribute('lang')
      const { data: couponInfo } = await axios(settings)

      let { data: cart } = await axios.get('/' + locale + '/api/v1/cart.json')
      cart = cart.data[0].cart

      if (!couponInfo.cart.couponCode) {
        this.isNotFound = true
      } else {
        this.isApplied = true
      }
      this.isLoading = false
      this.$emit('updateCart', cart)

      return false
    },
  },
}
</script>

<style scoped lang="scss">
.voucher {
  &__message {
    height: 24px;
    margin-top: 0.5rem;
    font-family: "FuturaPT", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #58ff74;
    transition: 0.3s;
    flex-grow: 1;

    &_warning {
      color: #ff5858;
    }
  }

  &__wrapper {
    margin-top: 1rem;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__button {
    outline: none;
    color: #fff;
    font-family: "FuturaPT", sans-serif;
    line-height: 1.5;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #fff;
      text-decoration: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &_loading {
      cursor: wait !important;
    }
  }

  &__input {
    padding: 12px 16px;
    border-radius: 2px;
    border: solid 2px #333;
    position: relative;
    display: block;
    color: #fff;
    outline: none;
    font-family: "FuturaPT", sans-serif;
    line-height: 1.5;
    transition: 0.3s;

    &:hover, &:focus {
      border-color: #616161;
    }
  }
}
</style>
