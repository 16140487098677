<template>
  <div class="overflow-hidden">
    <div v-for="(image, i) in privateState.els" :key="i" class="absolute z-0 absolute--fill cover bg-center" :style="{ backgroundImage: `url(${image})`, opacity: 0.1 }"></div>

    <transition-group
      @enter="enter"
      @leave="leave"
      :css="false"
    >
      <picture class=""
               v-show="i === privateState.activeEl"
               v-for="(image, i) in privateState.els" :key="`key-${i}`">
        <source media="(max-width: 560px)" :srcset="image.mobile">
        <source media="(max-width: 1024px)" :srcset="image.tablet">
        <img class="absolute z-0 absolute--fill fit-cover h-100 w-100"
             :src="image.desktop"
             :alt="image.alt">
      </picture>
    </transition-group>
  </div>
</template>

<script>
import anime from "animejs"
import s from "../store"

export default {
  name: "BackgroundImageFader",
  data () {
    return {
      privateState: {
        els: JSON.parse(this.els),
        activeEl: 0
      },
      sharedState: s.state
    }
  },
  props: {
    els: { type: String }
  },
  mounted () {
    this.updateActiveEl()
    // eslint-disable-next-line no-console
    console.log('privateState.els', this.privateState.els);
  },
  methods: {
    updateActiveEl: function () {
      setInterval(() => {
        if (this.privateState.activeEl < this.privateState.els.length - 1) {
          this.privateState.activeEl++
        } else {
          this.privateState.activeEl = 0
        }
      }, 3500)
    },
    enter: (el, done) => {
      anime({
        targets: el,
        opacity: [0, 1],
        duration: 800,
        easing: "easeOutCubic",
        complete: done
      })
    },
    leave: (el, done) => {
      anime({
        targets: el,
        opacity: 0,
        duration: 800,
        easing: "easeOutCubic",
        complete: done
      })
    }
  },
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
